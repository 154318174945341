import React from "react";
// react components used to create a google map
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
};

const RegularMap = withScriptjs(
    withGoogleMap(props => (
        <GoogleMap
            defaultZoom={17}
            defaultCenter={{ lat: 35.154999, lng: 33.371420 }}
            defaultOptions={{
                scrollwheel: false,
                streetViewControl: false,
                scaleControl: false,
                mapTypeControl: false,
                panControl: false,
                rotateControl: false,
                fullscreenControl: false
            }}
        >
            <Marker position={{ lat: 35.154999, lng: 33.371420 }} />
        </GoogleMap>
    ))
);

class GoogleMaps extends React.Component {
    render() {
        return (
            <div style={{ height: `100%`, width:`100%` }}>
                <RegularMap
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAWd2-RXMzXNacmlghy8Z1SmpK2-mnuO4o"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={
                        <div
                            style={{
                                height: `100%`,
                                minWidth: `100%`,
                                borderRadius: "6px",
                                overflow: "hidden"
                            }}
                        />
                    }
                    mapElement={<div style={{ height: `100%`, width:`100%` }} />}
                />
            </div>
        );
    }
}

export default withStyles(styles)(GoogleMaps);
