const regexs = {
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])(?=.*[!@#$£%^=&*])([^\s]){8,16}$/,
    mobile: /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/,
    code: /\b\d{8}\b/,
    username: /^[^@\.~\*\"\'\`\ ]([a-zA-z!#_\d]{3,50})$/,
    freeWord: /^([^\W])+$/, //disallows non alphanumeric characters
    numOnly: /^[0-9]\d*(\.\d+)?$/, /* /^\d+$/, /^[0-9]*$/ */
    initial: /^[a-zA-Z]$/,

    fullName: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
};

const phoneNumbers = {
    GB: /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/,
};

export const matchesRegex = (text, type) => regexs[type].test(text);

export const phoneRegex = (number, countryCode) => {
    if (phoneNumbers[countryCode]) {
        return phoneNumbers[countryCode].test(number);
    }
    return true;
};
