import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import LatestNewsSection from "./Sections/LatestNews.jsx";

import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";

function NewsPage({classes,...rest})
{
    return (
        <div>
            <Header
                isImage
                brand={require("assets/img/aMediaBlack.png")}
                rightLinks={<HeaderLinks />}
                fixed
                color="transparent"
                changeColorOnScroll={{
                    height: 400,
                    color: "blue"
                }}
                dontChangeColour={false}
                {...rest}
            />
            <Parallax image={require("assets/img/amedia2.png")}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem>
                            <div className={classes.brand}>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>

            <div className={classNames(classes.main, classes.mainRaised)} style={{marginBottom:100, paddingTop:100}}>
                <div className={classes.container}>
                    <LatestNewsSection />
                </div>
            </div>
            <div style={{maxWidth:"100%", height:600, paddingTop:60, backgroundImage:"url(" + require("assets/img/amedia1.png") + ")", backgroundPosition: "center center", backgroundSize: "cover", marginTop: 100}}/>
            <Footer whiteFont classes={{footer:classes.footer}}/>
        </div>
    );
}

export default withStyles(componentsStyle)(NewsPage);
