import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";
import image1 from "assets/img/image3.png"
import image2 from "assets/img/image2.svg"
import image3 from "assets/img/image1.png"

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

class ProductSection extends React.Component {
    render() {
        const {classes} = this.props;
        return (
            <div className={classes.section} style={{paddingTop: 20}}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={8}>
                        <h2 className={classes.title}>Portfolio</h2>
                    </GridItem>
                </GridContainer>
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <InfoArea
                                style={{height: 55}}
                                title="Amediastudio (Media City)"
                                description='Amedia studio is the largest privately-owned studio in Moscow.Since 2004,
Amedia studio has been providing a wide range of services for the production
of full-length movies, TV shows, music videos, and commercials. Almost 10
years after its renovation, about 500 projects were shot at the Amedia film
studio. The studio has 16 fully equipped and soundproof film sets with the
latest equipment, a costume and prop department offering more
than 10,000 items for rent, a screening room as well as facilities for
conferences, workshops, meetings, and seminars. Additionally, post-
production editing including audio and video remastering is also available.
Amediastudio currently collaborates with Good Story Media, Sreda Production
Company, Sony Pictures Television Russia, and many other TV and film production hubs located within
Amediastudio.'
                                icon={image1}
                                iconColor="info"
                                links={[{
                                    tooltip: "Amedia Studio",
                                    href: "http://amediastudio.ru/",
                                    icon: "fas fa-globe",
                                    name: "Amedia Studio"
                                }, {
                                    tooltip: "Amedia Studio Instagram",
                                    href: "https://www.instagram.com/amediastudio/",
                                    icon: "fab fa-instagram",
                                    name: "Amedia Studio Instagram"
                                }]}
                                vertical
                                linkColor="#444B88"
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <InfoArea
                                style={{height: 55}}
                                title="Amedia Production"
                                description="Amedia Production is one of the biggest TV and film production companies in
Russia. The Company provides end-to-end production services. It’s library consists of about 3000 hours of content and
almost 70 projects. Amedia Production has a vast catalogue of projects that
include TV shows, TV films, documentary films &amp; TV Programs such as T-34,
Pretender, Golden Horde, Hotel Russia, Ekaterina, and Boarding School – a
remake of the Spanish show, El Internado.
Simultaneously with the production of TV series, films and programs, Amedia
is searching and training new creative personnel through the project.
Specifically, it provides skills courses for actors. Visit the link above for further
information about the 2020 classes."
                                icon={image3}
                                iconColor="success"
                                vertical
                                linkColor="#D44845"
                                links={[{
                                    tooltip: "Amedia Film",
                                    href: "http://amediafilm.com/",
                                    icon: "fas fa-globe",
                                    name: "Amedia Film"
                                }, {
                                    tooltip: "Amedia Production Facebook",
                                    href: "https://www.facebook.com/amedia.production",
                                    icon: "fab fa-facebook",
                                    name: "Amedia Production Facebook"
                                }, {
                                    tooltip: "Amedia Production Instagram",
                                    href: "https://www.instagram.com/amediaproduction/",
                                    icon: "fab fa-instagram",
                                    name: "Amedia Instagram"
                                }, {
                                    tooltip: "Amedia Youtube",
                                    href: "https://www.youtube.com/user/SerialiTV",
                                    icon: "fab fa-youtube",
                                    name: "Amedia Youtube"
                                }]}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <InfoArea
                                style={{height: 95}}
                                title="Amediateka and Amedia TV"
                                description="Amedia TV was founded in 2011 as part of Amedia group and includes Video-
оn-Demand service Amediateka and four pay-TV channels – Amedia
Premium, Amedia Hit, Amedia I, and Amedia II. Amediateka is available on all
major platforms: web, mobile, smart TV, Apple TV, and set-top boxes.
It should be noted that in July 2017 Amedia Premium HD,
consisting of Amedia Premium and Amedia Hit channels and SVOD service
Amediateka signed an extended agreement with HBO, becoming ‘the home of

HBO’ for the entire country. It therefore has exclusive access to all new HBO
projects and unlimited access to the US TV network’s library in Russia.
Additionally Amediateka premieres top-rated TV series simultaneously with
the USA and the UK. Shows are available in Russian or with subtitles. Apart
from HBO, Amedia TV also provides content produced by leading studios and
TV channels such as, Showtime, CBS, Sky, Lionsgate, Starz, BBC,
Fremantle, and Studio Canal."
                                icon={image2}
                                iconColor="danger"
                                linkColor="#000000"
                                vertical
                                links={[{
                                    tooltip: "Amedia TV",
                                    href: " http://amediatv.ru/",
                                    icon: "fas fa-globe",
                                    name: "Amedia TV"
                                }, {
                                    tooltip: "Amediateka Twitter",
                                    href: "https://twitter.com/amediateka",
                                    icon: "fab fa-twitter",
                                    name: "Amediateka Twitter"
                                }, {
                                    tooltip: "Amediateka Instagram",
                                    href: "https://www.instagram.com/amediateka/?hl=en",
                                    icon: "fab fa-instagram",
                                    name: "Amediateka Instagram"
                                }, {
                                    tooltip: "Amedia Youtube",
                                    href: "https://www.youtube.com/user/Amediapremium",
                                    icon: "fab fa-youtube",
                                    name: "Amedia Youtube"
                                }]}
                            />
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        );
    }
}

export default withStyles(productStyle)(ProductSection);
