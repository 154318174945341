/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import { List, ListItem, withStyles } from "@material-ui/core";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import footerStyle from "assets/jss/material-kit-react/components/footerStyle.jsx";


function Footer({ ...props }) {
    const { classes, whiteFont } = props;
    const footerClasses = classNames({
        [classes.footer]: true,
        [classes.footerWhiteFont]: whiteFont
    });
    const aClasses = classNames({
        [classes.a]: true,
        [classes.footerWhiteFont]: whiteFont
    });
    const imageClasses = classNames(
        classes.imgRounded,
        classes.imgFluid,
        classes.imgRaised
    );
    return (
        <footer className={footerClasses}>
          <div className={classes.container}>
            <div style={{width:"100%", borderBottom:"solid 1px #999"}}>
            </div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a
                      href="/"
                      className={classes.block}
                  >
                    Amedia
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                      href="/ContactUs"
                      className={classes.block}
                  >
                    Contact us
                  </a>
                </ListItem>
                  <ListItem className={classes.inlineBlock}>
                      <a
                          href="/News"
                          className={classes.block}
                      >
                          LatestNews
                      </a>
                  </ListItem>
              </List>
            </div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} Amedia Networks
            </div>
          </div>
        </footer>
    );
}

Footer.propTypes = {
    classes: PropTypes.object.isRequired,
    whiteFont: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
