import React from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import teamStyle from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.jsx";
import AppStore from "assets/img/US-UK_RGB_blk_092917.svg";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

class AboutUsSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div style={{paddingBottom:0}} className={classes.section}>
        <h2 className={classes.title}  style={{paddingBottom:15}}>About Us</h2>
        <h5 style={{paddingBottom:10}} className={classes.description}>
            Amedia Networks Limited is a Cyprus-based company founded in 2005, with
            headquarters in Nicosia. Since our establishment, we have been involved in
            the media business sector, which is a very competitive and demanding
            market. The media landscape has changed significantly and rapidly over the
            past few years. However, our company has managed to build a reputation as
            a technology pioneer through its innovative approach. Moreover, in an effort
            to meet the expectations of our audience, we have developed our own online
            media distribution platform (OTT).
        </h5>
        <h5 style={{paddingBottom:10}} className={classes.description}>
            Since 2018, our cross-platform software provides users with access to video
            contents under a subscription model. It can be accessed through most web
            browsers or mobile browsers as well as the platform’s own mobile and Smart
            TV Apps. Our platform has a cross-platform functionality, which enables our
            subscribers to use one account for access from different devices. Indeed, with
            our interactive, state-of-the-art and user-friendly platform, we have taken
            entertainment to another level. At-home entertainment and streaming services
            have radically changed how consumers access film and TV. Today, our
            customers can enjoy our streaming services through any device, from any
            location. You can download our app and start browsing our massive gallery
            today.
        </h5>
        <h5 style={{paddingBottom:10}} className={classes.description}>
            Apart from our own online media distribution platform, the Company owns the
            majority stake in Amedia Group, Russia’s largest TV producer. The Amedia
            group of companies was founded in Moscow in 2002 and has become a key player in the Russian
            television and film industry.
        </h5>
          <h5 style={{paddingBottom:10}} className={classes.description}>
              Amedia group of companies includes the Amedia Studio, one of the largest
              TV studios in Russia, Amedia Production, which produces TV films and
              programs and Amedia TV which is the leader of the Russian market of
              premium pay-TV and Video on Demand. See more about our portfolio
              following the following links.
          </h5>
          <h5 style={{paddingBottom:10}} className={classes.description}>
              Our Company’s auditor is Deloitte Limited Cyprus.
          </h5>
          <h5 style={{paddingBottom:10}} className={classes.description}>
              <GridContainer>
                  <GridItem md={2} lg={4}/>
                  <GridItem xs={12} md={8} lg={4}>
                      <GridContainer>
                  <GridItem style= {{textAlign: "center"}} xs={6}>
                      <a href='https://play.google.com/store/apps/details?id=com.amdteka&hl=en_US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img style={{maxWidth:150}} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                  </GridItem>
                  <GridItem style={{textAlign: "center"}} xs={6}>
                      <a href='https://apps.apple.com/us/app/amediateka-%D1%81%D0%B5%D1%80%D0%B8%D0%B0%D0%BB%D1%8B-%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD/id695094274?mt=8'><img style={{maxWidth:150,marginTop:10}} alt='Get it on App Store' src={AppStore}/></a>
                  </GridItem>
                      </GridContainer>
                  </GridItem>
              </GridContainer>
          </h5>
      </div>
    );
  }
}

export default withStyles(teamStyle)(AboutUsSection);
