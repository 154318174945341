import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GoogleParallax from "components/GoogleParallax/GoogleParallax.jsx";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import ContactSection from "./Sections/ContactSection.jsx";
import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";

class ContactUs extends React.Component {
    render() {
        const { classes, ...rest } = this.props;
        return (
            <div>
                <Header
                    isImage
                    brand={require("assets/img/aMediaBlack.png")}
                    rightLinks={<HeaderLinks color="black"/>}
                    fixed
                    color="transparent"
                    changeColorOnScroll={{
                        height: 200,
                        color: "blue"
                    }}
                    dontChangeColour
                    {...rest}
                />

                <GoogleParallax/>

                <div className={classNames(classes.main, classes.mainRaised)} style={{marginBottom:100}}>
                    <div className={classes.container}>
                        <ContactSection />
                    </div>
                </div>
                <div style={{maxWidth:"100%", height:600, paddingTop:60, backgroundImage:"url(" + require("assets/img/amediaBackground3.jpg") + ")", backgroundPosition: "center center", backgroundSize: "cover", marginTop: 100}}/>
                <Footer whiteFont classes={{footer:classes.footer}}/>
            </div>
        );
    }
}

export default withStyles(componentsStyle)(ContactUs);
