import LandingPage from "views/LandingPage/LandingPage.jsx";
import ContactUs from "views/ContactUs/ContactUs.jsx";
import NewsPage from "views/News/NewsPage";

var indexRoutes = [
  { path: "/ContactUs", name: "ContactUs", component: ContactUs},
  { path: "/News", name: "News", component: NewsPage},
  { path: "/", name: "LandingPage", component: LandingPage }
];

export default indexRoutes;
