/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Hidden from "@material-ui/core/Hidden";

import InfoLinksStyle from "assets/jss/material-kit-react/components/InfoLinksStyle.jsx";

function InfoLinks({ ...props }) {
    const { classes, links} = props;
    return (
        <div>
        <List className={classes.list}>
            <ListItem className={classes.listItem}>
            {links.map((link,index) =>{
                return <Tooltip
                    key={index}
                        id={link.href}
                        title={link.tooltip}
                        placement={window.innerWidth > 959 ? "top" : "left"}
                        classes={{ tooltip: classes.tooltip }}
                    >
                        <Button
                            href={link.href}
                            target="_blank"
                            color="transparent"
                            className={classes.navLink}
                        >
                            <i style={{color:props.linkColor}} className={classes.socialIcons +" " + link.icon} />
                        </Button>
                    </Tooltip>
            })}
            </ListItem>
        </List>
        </div>
    );
}

export default withStyles(InfoLinksStyle)(InfoLinks);
