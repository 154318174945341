/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Hidden from "@material-ui/core/Hidden";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

function HeaderLinks({ ...props }) {
  const { classes, color } = props;
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="linkedin-link"
          title="Find us on linkedin"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://www.linkedin.com/company/amedia-networks-limited"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i style={color?{color:color}:{}} className={classes.socialIcons + " fab fa-linkedin"} />
              <Hidden mdUp >
                  <h6>LinkedIn</h6>
              </Hidden>
          </Button>
        </Tooltip>
      </ListItem>
        <ListItem className={classes.listItem}>
            <Tooltip
                id="instagram-contact"
                title="Contact Us"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
            >
                <Button
                    color="transparent"
                    href="/ContactUs"
                    className={classes.navLink}
                >
                    <i style={color?{color:color}:{}} className={classes.socialIcons + " fas fa-map-marker-alt"} />
                    <Hidden mdUp >
                        <h6>Contact Us</h6>
                    </Hidden>
                </Button>
            </Tooltip>
        </ListItem>
        <ListItem className={classes.listItem}>
            <Tooltip
                id="news"
                title="Latest News"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
            >
                <Button
                    color="transparent"
                    href="/News"
                    className={classes.navLink}
                >
                    <i style={color?{color:color}:{}} className={classes.socialIcons + " fas fa-newspaper"} />
                    <Hidden mdUp >
                        <h6>Latest News</h6>
                    </Hidden>
                </Button>
            </Tooltip>
        </ListItem>
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
