import React from "react";
import {ReactTinyLink} from "react-tiny-link";

// nodejs library that concatenates classes
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/DashboardCard/DashboardCard.jsx";
import CardBody from "components/DashboardCard/DashboardCardBody.jsx";

import latestNewsStyle from "assets/jss/material-kit-react/views/ecommercePageSections/latestNewsStyle.jsx";

function LatestNewsSection({classes})
    {
        return (<div style={{paddingTop:10}} className={classes.section}>
                <h2 style={{paddingBottom:10}} className={classes.title}>Latest News</h2>
                <div>
                    <GridContainer>
                        <GridItem className={classes.topSpace} xs={12} sm={12} md={4}>
                            <ReactTinyLink
                                header="The film about the T-34 tankers raised more than 2 billion rubbles"
                                description=" "
                                cardSize="large"
                                showGraphic={true}
                                maxLine={2}
                                minLine={1}
                                url="https://www.vedomosti.ru/technology/articles/2019/01/21/791965-t-34"
                            />
                        </GridItem>
                        <GridItem className={classes.topSpace} xs={12} sm={12} md={4}>
                            <ReactTinyLink
                                header="T-34 breaks records in the Russian box office"
                                description=" "
                                cardSize="large"
                                showGraphic={true}
                                maxLine={2}
                                minLine={1}
                                url="https://www.vestifinance.ru/articles/112738"
                            />
                        </GridItem>
                        <GridItem className={classes.topSpace} xs={12} sm={12} md={4}>
                            <ReactTinyLink
                                header="Amedia TV Licenses Lionsgate Content in Exclusive Deal"
                                description=" "
                                cardSize="large"
                                showGraphic={true}
                                maxLine={2}
                                minLine={1}
                                url="https://www.hollywoodreporter.com/news/russias-amedia-tv-licenses-lionsgate-content-deal-1227631"
                            />
                        </GridItem>
                        <GridItem className={classes.topSpace} xs={12} sm={12} md={4}>
                            <ReactTinyLink
                                header="Showtime and CBS series will stream exclusively on Amedia TV"
                                description=" "
                                cardSize="large"
                                showGraphic={true}
                                maxLine={2}
                                minLine={1}
                                url="https://www.hollywoodreporter.com/news/showtime-cbs-all-access-series-sell-russian-streamer-amedia-1213767"
                            />
                        </GridItem>
                        <GridItem className={classes.topSpace} xs={12} sm={12} md={4}>
                            <ReactTinyLink
                                header='The agreement makes the Russian company the "Home of HBO"'
                                description=" "
                                cardSize="large"
                                showGraphic={true}
                                maxLine={2}
                                minLine={1}
                                url="https://www.hollywoodreporter.com/news/hbo-russias-amedia-strike-deal-1024142"
                            />
                        </GridItem>
                        <GridItem className={classes.topSpace} xs={12} sm={12} md={4}>
                            <ReactTinyLink
                                header='AMEDIATEKAs Exclusive Content'
                                description=" "
                                cardSize="large"
                                showGraphic={true}
                                maxLine={2}
                                minLine={1}
                                url="http://www.kinometro.ru/tv/show/name/Amediateka_DAZN_8774"
                            />
                        </GridItem>
                        <GridItem className={classes.topSpace} xs={12} sm={12} md={4}>
                            <ReactTinyLink
                                header='Amedia and HBO Renew Pact'
                                description=" "
                                cardSize="large"
                                showGraphic={true}
                                maxLine={2}
                                minLine={1}
                                url="https://variety.com/2017/tv/news/amedia-hbo-deal-game-of-thrones-1202505651/"
                            />
                        </GridItem>
                        <GridItem className={classes.topSpace} xs={12} sm={12} md={4}>
                            <ReactTinyLink
                                header='The revenue of Amediateka is growing faster than the market'
                                description=" "
                                cardSize="large"
                                showGraphic={true}
                                maxLine={2}
                                minLine={1}
                                url="https://www.vedomosti.ru/technology/articles/2017/03/28/682980-onlain-kinoteatra-amediateka"
                            />
                        </GridItem>
                        <GridItem className={classes.topSpace} xs={12} sm={12} md={4}>
                            <ReactTinyLink
                                header='"Poor Nastya": Hollywood enters the Russian TV series market'
                                description=" "
                                cardSize="large"
                                showGraphic={true}
                                maxLine={2}
                                minLine={1}
                                url="https://www.kommersant.ru/doc/425171"
                            />
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        )
}

export default withStyles(latestNewsStyle)(LatestNewsSection);
